import { useMediaQuery, useTheme } from "@mui/material";
import copy from "copy-to-clipboard";
import { uniq } from "lodash";
import moment, { Moment } from "moment";

import { getDateIntervalLabel } from "pages/trades/components/Filters/constants";
import {
  SortingDirection,
  DateRange,
  BatchesPaginationType,
  BatchesSortingType,
  Vendor,
} from "types";

export const withId = (url: string, id: string | number) =>
  url.replace(":id", id as string);

export const withToken = (url: string, token: string) =>
  url.replace(":token", token as string);

export const hasAnyKeyWithValue = (obj: object) => {
  let result = false;
  Object.values(obj).forEach((value: string) => {
    if (value) result = true;
  });
  return result;
};

export const useMobile = (breakpoint: "lg" | "md" | "sm" = "lg") => {
  const theme = useTheme();
  return useMediaQuery(`(max-width:${theme.breakpoints.values[breakpoint]}px)`);
};

export const copyToClipboard = (text: string) => copy(text);

export const convertToCurrency = (value?: number | string) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(Number(value));
};

export const formatNumber = (value?: number | string) => {
  const formatter = new Intl.NumberFormat("pt-BR");

  return formatter.format(Number(value));
};

export const batchesPaginationParam = (pagination: BatchesPaginationType) => {
  const filters: { [key: string]: number } = {};

  Object.entries(pagination).forEach(([batchId, params]) => {
    Object.entries(params).forEach(([param, value]) => {
      filters[`pagination[${batchId}][${param}]`] = value;
    });
  });

  return filters;
};

export const batchesSortingParam = (sorting: BatchesSortingType) => {
  const filters: { [key: string]: string } = {};

  Object.entries(sorting).forEach(([batchId, params]) => {
    const { sortingKey, direction } = params;
    if (sortingKey) filters[`sort[${batchId}]`] = `${sortingKey} ${direction}`;
  });

  return filters;
};

export const sortingParam = (sorting?: {
  direction: SortingDirection;
  sortingKey: string;
}) => {
  const { sortingKey, direction } = sorting || {};

  return sortingKey ? { "q[s]": `${sortingKey} ${direction}` } : {};
};

export const dateParam = (key: string, date?: DateRange) => {
  if (!date) return null;
  let result = {};
  if (date.startDate) {
    result = {
      ...result,
      [`q[${key}_gteq]`]: moment(date.startDate).format("DD/MM/YYYY"),
    };
  }
  if (date.endDate) {
    result = {
      ...result,
      [`q[${key}_lteq]`]: moment(date.endDate).format("DD/MM/YYYY"),
    };
  }
  return result;
};

export const dateParamToRange = (
  dateKeys: string[],
  filters: { [key: string]: string | undefined },
  filterKeysMap: { [key: string]: string }
): { [key: string]: DateRange } => {
  const dateFilters: { [key: string]: DateRange } = {};

  const cleanedKeys = dateKeys.map((key) =>
    key.replace(/(q\[|(_gteq|_lteq)\])/g, "")
  );

  uniq(cleanedKeys).forEach((key) => {
    const startDate = filters[`q[${key}_gteq]`];
    const endDate = filters[`q[${key}_lteq]`];

    if (startDate || endDate) {
      const mappedKey = filterKeysMap[key];
      dateFilters[mappedKey] = {};
      if (startDate)
        dateFilters[mappedKey].startDate = moment(startDate, "DD/MM/YYYY");
      if (endDate)
        dateFilters[mappedKey].endDate = moment(endDate, "DD/MM/YYYY").endOf(
          "day"
        );
      const label = getDateIntervalLabel(key, dateFilters[mappedKey]);
      if (label) dateFilters[mappedKey].label = label;
    }
  });

  return dateFilters;
};

export const parseNumber = (value: string): number =>
  parseFloat(value.replace(/[^0-9,]/g, "").replace(",", "."));

export const cleanDiacritics = (value: string): string =>
  value.normalize("NFD").replace(/\p{Diacritic}/gu, "");

export const validateDateRange = (dateRange?: DateRange) => {
  if (!dateRange) return true;

  const rangeStartValid =
    (dateRange?.startDate && dateRange?.startDate?.isValid()) ||
    !dateRange?.startDate;

  const rangeEndValid =
    (dateRange?.endDate && dateRange?.endDate.isValid()) || !dateRange?.endDate;

  return rangeStartValid && rangeEndValid;
};

export const parseDate = (dateString?: string, dateFormat = "DD/MM/YYYY") =>
  moment(dateString, dateFormat);

export const formatDate = (date?: Moment) => date?.format("DD/MM/YYYY");

export const nestFilters = (filters?: { [key: string]: unknown }) => {
  if (!filters) return {};

  const nestedParams: { q: { [key: string]: unknown } } = { q: {} };

  Object.entries(filters).forEach(([filterKey, value]) => {
    const pattern = /q\[([A-Za-z_]+)\]$/;

    const match = pattern.exec(filterKey);
    if (!match) return;

    const [, filterKeyName] = match;

    nestedParams.q[filterKeyName] = value;
  });

  return nestedParams;
};

export const formatVendorsOptions = (vendors: Vendor[]) =>
  vendors.map(({ company, id, cnpj, tradeName }) => ({
    title: company,
    id,
    cnpj,
    tradeName,
  }));

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;
