import { useState, useContext, useEffect } from "react";
import Joyride, {
  CallBackProps,
  STATUS,
  ACTIONS,
  LIFECYCLE,
  StoreHelpers,
} from "react-joyride";

import { TourContext } from "contexts/TourContext";
import { dispatchAmplitudeEvent } from "services/amplitude";
import { useMobile } from "utils";

import TourTooltip from "./components/TourTooltip";

const Tour: React.FC = () => {
  const isMobile = useMobile("lg");

  const [helpers, setHelpers] = useState<StoreHelpers>();
  const [runTour, setRunTour] = useState<boolean>(false);

  const { currentTour, checkQueue, setRanTour } = useContext(TourContext);

  const { steps, identifier } = currentTour || {
    steps: [],
    startContent: { title: "", body: "" },
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index, lifecycle } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (lifecycle === LIFECYCLE.BEACON && index === 0) {
      helpers?.open();
    }

    if (action === ACTIONS.CLOSE) {
      dispatchAmplitudeEvent(`Buyer ${identifier}: tour skip`, {
        index,
        button: "out_of_tooltip",
      });
    }

    if (finishedStatuses.includes(status)) {
      setRunTour(false);
      checkQueue();
      if (status === STATUS.FINISHED) setRanTour(identifier);
    }
  };

  useEffect(() => {
    setRunTour(Boolean(currentTour));
  }, [currentTour]);

  if (isMobile) return <></>;

  return (
    <>
      <Joyride
        run={runTour}
        callback={handleJoyrideCallback}
        getHelpers={setHelpers}
        continuous
        disableScrolling
        disableCloseOnEsc
        showProgress
        showSkipButton
        beaconComponent={() => <></>}
        tooltipComponent={(props) => (
          <TourTooltip identifier={identifier} {...props} />
        )}
        steps={steps}
        styles={{
          options: {
            zIndex: 999999,
          },
          overlay: {
            backgroundColor: "rgb(68, 75, 84)",
          },
        }}
        locale={{
          back: "Voltar",
          close: "Fechar",
          last: "Concluir",
          next: "Próximo",
          open: "Abrir",
          skip: "Cancelar",
        }}
      />
    </>
  );
};

export default Tour;
