import styled from 'styled-components';

import { BANNER_HEIGHT } from './constants';

export const Content = styled.div`
  padding: 40px 32px 40px 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding: 32px 24px 0 24px;
  }
`;

export const Container = styled.div`
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding: 0 0 80px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 0 80px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    max-width: 1360px;
  }
`;

export const ContainerWrapper = styled.div<{ sidebarOpen: boolean; marginTop?: number }>`
  padding-left: ${({ sidebarOpen }) => (sidebarOpen ? '260px' : '72px')};
  transition: padding-left 0.15s;
  width: 100%;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding-left: 0;
  }
`;

export const BannerWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

export const MessageBannerWrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

export const MessageBannerSpacer = styled.div`
  height: ${BANNER_HEIGHT}px;
`;
