import React, { createContext, useState, useEffect } from "react";

import { fetchSystemInfo } from "services/api";
import { SystemInfoInterface } from "types";

import { SystemInfoContextInterface } from "./types";

export const SystemInfoContext = createContext<SystemInfoContextInterface>({
  loading: true,
});

type ISystemInfoProviderProps = {
  children: React.ReactNode;
};

export const SystemInfoProvider = ({ children }: ISystemInfoProviderProps) => {
  const [systemInfo, setSystemInfo] = useState<SystemInfoInterface>();
  const [loading, setLoading] = useState<boolean>(true);

  const getSystemInfo = async () => {
    const { status, data } = await fetchSystemInfo();
    if (status) {
      setSystemInfo(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getSystemInfo();
  }, []);

  return (
    <SystemInfoContext.Provider value={{ ...systemInfo, loading }}>
      {children}
    </SystemInfoContext.Provider>
  );
};
