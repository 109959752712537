import { Typography, Button as RawButton } from '@libercapital/react-salti';
import styled from 'styled-components';

export const TooltipBody = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 16px;
  position: relative;
  width: 360px;
  box-shadow: 0px 8px 6px -2px rgba(106, 124, 160, 0.2), 0px 24px 44px 6px rgba(80, 98, 134, 0.32);
`;

export const TooltipTitle = styled(Typography).attrs(() => ({
  color: 'textPrimary',
  variant: 'h4',
}))`
  margin-bottom: 8px;
`;

export const Button = styled(RawButton).attrs(() => ({
  variant: 'text',
  size: 'small',
}))``;

export const NextButton = styled(RawButton).attrs(() => ({
  variant: 'outlined',
  size: 'small',
}))``;

export const SkipContainer = styled.div`
  flex: 1 1 0%;
`;

export const TooltipFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  & > button:not(:last-of-type) {
    margin-right: 8px;
  }
`;
