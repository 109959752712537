import React, { createContext, useContext, useState, useEffect } from "react";
import { isEqual, merge } from "lodash";

import { Tour } from "types";
import Cookie, { keys } from "services/cookie";
import { getDocument, setDocument } from "services/firebase";
import { RAN_TOURS_COLLECTION } from "services/firebase/constants";
import { useMobile } from "utils";
import { UserInfoContext } from "./UserInfoContext";

import { TourContextInterface } from "./types";

type ITourProviderProps = {
  children: React.ReactNode;
};

export const TourContext = createContext<TourContextInterface>({
  enqueueTour: () => undefined,
  checkQueue: () => undefined,
  setRanTour: () => undefined,
});

export const TourProvider = ({ children }: ITourProviderProps) => {
  const isMobile = useMobile("lg");

  const { firebaseLoading } = useContext(UserInfoContext);

  const [pendingToursQueue, setPendingToursQueue] = useState<Tour[]>([]);
  const [toursQueue, setToursQueue] = useState<Tour[]>([]);
  const [currentTour, setCurrentTour] = useState<Tour>();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [remoteRanTours, setRemoteRanTours] =
    useState<Record<string, boolean>>();

  const getCookiesRanTours = () => Cookie.get(keys.RAN_TOURS) || {};

  const enqueueTour = (tour: Tour) => {
    if (isMobile) return;

    if (remoteRanTours === undefined) {
      setPendingToursQueue((previousPendingTours) => [
        ...previousPendingTours,
        tour,
      ]);
      return;
    }

    const cookiesRanTours = getCookiesRanTours();
    if (cookiesRanTours[tour.identifier] || remoteRanTours[tour.identifier])
      return;

    setToursQueue((prevToursQueue) => [...prevToursQueue, tour]);
  };

  const loadTourAtIndex = (index: number) => {
    setCurrentIndex(index);
    setCurrentTour(toursQueue[index]);
  };

  const checkQueue = () => {
    if (toursQueue.length > currentIndex + 1) {
      loadTourAtIndex(currentIndex + 1);
    } else {
      setCurrentTour(undefined);
    }
  };

  const clearQueue = () => {
    setCurrentTour(undefined);
    setToursQueue([]);
    setCurrentIndex(-1);
  };

  const setRanTour = async (tourId?: string) => {
    const cookiesRanTours = getCookiesRanTours();
    const mergedRanTours = merge(cookiesRanTours, remoteRanTours);

    if (tourId) {
      mergedRanTours[tourId] = true;
      Cookie.set(keys.RAN_TOURS, mergedRanTours);
      setDocument(RAN_TOURS_COLLECTION, mergedRanTours);
    }
  };

  const getRanTours = async () => {
    const { status, data } = await getDocument(RAN_TOURS_COLLECTION);

    if (status) setRemoteRanTours(data ?? {});
  };

  const checkRemoteConsistency = async () => {
    const cookiesRanTours = getCookiesRanTours();
    if (!isEqual(cookiesRanTours, remoteRanTours)) {
      setDocument(RAN_TOURS_COLLECTION, cookiesRanTours);
    }
  };

  useEffect(() => {
    if (remoteRanTours !== undefined) {
      const pendingTours = pendingToursQueue.filter(
        ({ identifier }) => !remoteRanTours[identifier]
      );
      pendingTours.forEach(enqueueTour);
      setPendingToursQueue([]);
      checkRemoteConsistency();
    }
  }, [remoteRanTours]);

  useEffect(() => {
    if (!firebaseLoading) getRanTours();
  }, [firebaseLoading]);

  useEffect(() => {
    if (!currentTour) checkQueue();
  }, [toursQueue]);

  useEffect(() => {
    if (isMobile) clearQueue();
  }, [isMobile]);

  return (
    <TourContext.Provider
      value={{ currentTour, enqueueTour, checkQueue, setRanTour }}
    >
      {children}
    </TourContext.Provider>
  );
};
