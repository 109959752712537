import { History } from 'history';
import { SPA_PATHS } from '../paths';
import { ID_BLACKLIST } from './constants';

const mapPathToRegex = (path: string): RegExp =>
  new RegExp(
    `${path.replace(/:[a-zA-Z]+/, `(?!(${ID_BLACKLIST.join('|')}))[a-zA-Z0-9-]+`)}/{0,1}$`,
  );

class Navigation {
  static isMonolithRoute = (path: string) =>
    !SPA_PATHS.some(spaPath => mapPathToRegex(spaPath).test(path));

  static navigate(path: string, history: History | undefined = undefined) {
    if (Navigation.isMonolithRoute(path)) {
      window.location.assign(path);
    } else {
      history?.push(path);
    }
  }
}

export default Navigation;
