import { useState, useEffect } from "react";

import Cookie, { keys } from "services/cookie";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookie.get(keys.JWT);

    if (token) setAuthenticated(true);

    setLoading(false);
  }, []);

  return { authenticated, loading };
}
