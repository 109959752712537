import { DateRange } from 'types';
import {
  DUPLICATA_EXPECTED_PAYMENT_AT,
  DUPLICATA_EXPIRES_AT,
  FINISHED_AT,
} from 'contexts/constants/trades';

import {
  getIntervalDays,
  SHOW_ALL,
  ALL_NOT_EXPIRED,
  EXPIRES_TODAY,
  EXPIRES_THIS_WEEK,
  EXPIRES_THIS_MONTH,
  EXPIRES_THIS_QUARTER,
  ALL_EXPIRED,
  EXPIRED_THIS_WEEK,
  EXPIRED_THIS_MONTH,
  EXPIRED_THIS_QUARTER,
  SETTLES_TODAY,
  SETTLES_THIS_WEEK,
  SETTLES_THIS_MONTH,
  SETTLES_THIS_QUARTER,
  SETTLED_THIS_WEEK,
  SETTLED_THIS_MONTH,
  SETTLED_THIS_QUARTER,
  TRADED_TODAY,
  TRADED_THIS_WEEK,
  TRADED_THIS_MONTH,
  TRADED_THIS_QUARTER,
} from 'utils/dateIntervals';

export const getDateIntervalLabel = (filterKey: string, range: DateRange): string | undefined => {
  const { startDate, endDate } = range;
  if (!startDate && !endDate) return SHOW_ALL;

  const {
    todayStart,
    todayEnd,
    weekStart,
    weekEnd,
    monthStart,
    monthEnd,
    quarterStart,
    quarterEnd,
  } = getIntervalDays();

  switch (filterKey) {
    case DUPLICATA_EXPIRES_AT: {
      if (startDate?.isSame(todayStart) && endDate === undefined) return ALL_NOT_EXPIRED;
      if (startDate?.isSame(todayStart) && endDate?.isSame(todayEnd)) return EXPIRES_TODAY;
      if (startDate?.isSame(todayStart) && endDate?.isSame(weekEnd)) return EXPIRES_THIS_WEEK;
      if (startDate?.isSame(todayStart) && endDate?.isSame(monthEnd)) return EXPIRES_THIS_MONTH;
      if (startDate?.isSame(todayStart) && endDate?.isSame(quarterEnd)) return EXPIRES_THIS_QUARTER;
      if (startDate === undefined && endDate?.isSame(todayStart)) return ALL_EXPIRED;
      if (startDate?.isSame(weekStart) && endDate?.isSame(todayStart)) return EXPIRED_THIS_WEEK;
      if (startDate?.isSame(monthStart) && endDate?.isSame(todayStart)) return EXPIRED_THIS_MONTH;
      if (startDate?.isSame(quarterStart) && endDate?.isSame(todayStart))
        return EXPIRED_THIS_QUARTER;
      break;
    }
    case DUPLICATA_EXPECTED_PAYMENT_AT: {
      if (startDate?.isSame(todayStart) && endDate?.isSame(todayEnd)) return SETTLES_TODAY;
      if (startDate?.isSame(todayStart) && endDate?.isSame(weekEnd)) return SETTLES_THIS_WEEK;
      if (startDate?.isSame(todayStart) && endDate?.isSame(monthEnd)) return SETTLES_THIS_MONTH;
      if (startDate?.isSame(todayStart) && endDate?.isSame(quarterEnd)) return SETTLES_THIS_QUARTER;
      if (startDate?.isSame(weekStart) && endDate?.isSame(todayStart)) return SETTLED_THIS_WEEK;
      if (startDate?.isSame(monthStart) && endDate?.isSame(todayStart)) return SETTLED_THIS_MONTH;
      if (startDate?.isSame(quarterStart) && endDate?.isSame(todayStart))
        return SETTLED_THIS_QUARTER;
      break;
    }
    case FINISHED_AT: {
      if (startDate?.isSame(todayStart) && endDate?.isSame(todayEnd)) return TRADED_TODAY;
      if (startDate?.isSame(weekStart) && endDate?.isSame(weekEnd)) return TRADED_THIS_WEEK;
      if (startDate?.isSame(monthStart) && endDate?.isSame(monthEnd)) return TRADED_THIS_MONTH;
      if (startDate?.isSame(quarterStart) && endDate?.isSame(quarterEnd))
        return TRADED_THIS_QUARTER;
      break;
    }
    default:
      break;
  }
  return undefined;
};
