import { createContext, useCallback, useContext, useState } from 'react';
import { fetchVendorsPublicInfo, updateVendorStatus } from 'services/api';
import {
  IUpdateVendorStatusRequestBody,
  IExternalVendorApprovalContextData,
  IExternalVendorApprovalProviderProps,
  IVendorInfo,
} from './interfaces';

export const ExternalVendorApprovalContext = createContext<IExternalVendorApprovalContextData>(
  {} as IExternalVendorApprovalContextData,
);

const ExternalVendorApprovalProvider = ({ children }: IExternalVendorApprovalProviderProps) => {
  const [vendorInfo, setVendorInfo] = useState({} as IVendorInfo);

  const handleFetchVendorInfo = useCallback(async (token: string) => {
    const { status, data } = await fetchVendorsPublicInfo(token);

    if (status) {
      setVendorInfo(data);
    }
  }, []);

  const handleUpdateVendorStatus = useCallback(
    async (token: string, requestBody: IUpdateVendorStatusRequestBody) => {
      await updateVendorStatus(token, requestBody);
    },
    [],
  );

  const contextData: IExternalVendorApprovalContextData = {
    fetchVendorInfo: handleFetchVendorInfo,
    updateVendorStatus: handleUpdateVendorStatus,
    vendorInfo,
  };

  return (
    <ExternalVendorApprovalContext.Provider value={contextData}>
      {children}
    </ExternalVendorApprovalContext.Provider>
  );
};

function useExternalVendorApproval(): IExternalVendorApprovalContextData {
  const context = useContext(ExternalVendorApprovalContext);
  return context;
}

export { ExternalVendorApprovalProvider, useExternalVendorApproval };
