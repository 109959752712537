import React, { createContext } from "react";

import { UserInfoContextType } from "types";
import useUserInfo from "./hooks/useUserInfo";

export const UserInfoContext = createContext<UserInfoContextType>({
  firebaseLoading: false,
  loading: true,
  blockInvoiceEdition: false,
  id: undefined,
  operatorId: undefined,
  operator: undefined,
  portal: undefined,
  expiresIn: undefined,
  certifier: undefined,
  loggedAsOperator: undefined,
  anticipationLimitsAvailable: undefined,
  invoicesGroupingPolicy: undefined,
  invoicesConfirmationPolicy: undefined,
  billPolicy: undefined,
});

type IUserInfoProviderProps = {
  children: React.ReactNode;
};

export const UserInfoProvider = ({ children }: IUserInfoProviderProps) => {
  const userInfo = useUserInfo();

  return (
    <UserInfoContext.Provider value={{ ...userInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};
