export const TRADES_INDEX_ROUTE = "/api/v1/buyer/trades";
export const TRADES_SUMMARY_ROUTE = "/api/v1/buyer/trades/summary";
export const TRADES_FILTERS_ROUTE = "/api/v1/buyer/trades/vendors_and_funders";
export const INVOICES_INDEX_ROUTE = "/api/v1/buyer/invoices";
export const INVOICES_GROUPED_INDEX_ROUTE =
  "/api/v1/buyer/invoices/grouped_by_batch";
export const INVOICE_SHOW_ROUTE = "/api/v1/buyer/invoices/:id";
export const INVOICE_PENDING_ROUTE = "/api/v1/buyer/invoices/pending";
export const INVOICE_EDIT_ROUTE = "/api/v1/buyer/invoices/:id";
export const INVOICE_BLOCK_ROUTE = "/api/v1/buyer/invoices/block";
export const INVOICE_UNBLOCK_ROUTE = "/api/v1/buyer/invoices/unblock";
export const USER_INFO_ROUTE = "/api/v1/buyer/user";
export const SYSTEM_INFO_ROUTE = "/api/v1/buyer/info";
export const TRADES_REPORT_ROUTE = "/api/v1/buyer/reports/trades";
export const INVOICES_FUNDERS_SUMMARY_ROUTE =
  "/api/v1/buyer/invoices/summary_by_funder";
export const INVOICES_VENDORS_SUMMARY_ROUTE =
  "/api/v1/buyer/invoices/summary_by_vendor";
export const INVOICES_REPORT_ROUTE = "/api/v1/buyer/reports/invoices";
export const CREATE_INVOICES_ROUTE = "/api/v1/buyer/tax_documents";
export const REPORTS_INFO_ROUTE = "/api/v1/buyer/external_reports/:id";
export const REPORTS_INDEX_ROUTE = "/api/v1/buyer/external_reports";
export const REPORTS_CREATE_ROUTE = "/api/v1/buyer/external_reports";
export const REPORTS_DOWNLOAD_ROUTE =
  "/api/v1/buyer/external_reports/:id/download";
export const VENDORS_GROUP_ROUTE = "api/v1/buyer/vendors_groups";
export const VENDORS_GROUP_UPDATE_ROUTE = "api/v1/buyer/vendors_groups/:id";
export const VENDORS_PUBLIC_INFO_ROUTE = "/api/v1/public/vendors/:token";
export const UPDATE_VENDOR_STATUS_ROUTE =
  "/api/v1/public/vendors/:token/update_vendor_extra_fields_and_blocked_organization";
export const RATE_CHART_ROUTE = "/api/v1/buyer/dashboard/funders/rates";
export const CURRENT_WALLET_CHART_ROUTE =
  "/api/v1/buyer/dashboard/funders/current_wallet";
export const FUNDER_TOTAL_OPERATIONS_CHART_ROUTE =
  "/api/v1/buyer/dashboard/funders/buyer_funder_total_operations";
export const WEIGHTED_TERMS_CHART_ROUTE =
  "/api/v1/buyer/dashboard/funders/weighted_term";
export const WEIGHTED_RATES_CHART_ROUTE =
  "/api/v1/buyer/dashboard/funders/weighted_agreed_rate";
export const VENDORS_TPV_CHART_ROUTE =
  "/api/v1/buyer/dashboard/operations/buyer_vendor_tpv";
export const VENDORS_CURRENT_WALLET_CHART_ROUTE =
  "/api/v1/buyer/dashboard/vendors/current_wallet";
export const VENDORS_OUTLOOK_CHART_ROUTE =
  "/api/v1/buyer/dashboard/vendors/outlook";
export const VENDORS_WEIGHTED_AGREED_RATE_ROUTE =
  "/api/v1/buyer/dashboard/vendors/weighted_agreed_rate";
export const VENDORS_PENETRATION_TOTAL_VALUE_ROUTE =
  "/api/v1/buyer/dashboard/vendors/penetration";
export const VENDORS_WEIGHTED_TERM_ROUTE =
  "/api/v1/buyer/dashboard/vendors/weighted_term";
export const OPERATIONS_SUMMARY_ROUTE =
  "/api/v1/buyer/dashboard/operations/summary";

export const DASHBOARD_REPORTS_TRADES_SETTLEMENTS =
  "/api/v1/buyer/dashboard_reports/trades_settlements";
export const DASHBOARD_REPORTS_RATES_CURVE =
  "/api/v1/buyer/dashboard_reports/rates_curve";
export const DASHBOARD_REPORTS_TRADES_DISTRIBUTION_FUNDER =
  "/api/v1/buyer/dashboard_reports/trades_distribution_funder";
export const DASHBOARD_REPORTS_TRADES_OPERATION_TERM =
  "/api/v1/buyer/dashboard_reports/weighted_term";
export const DASHBOARD_REPORTS_TRADES_OPERATION_RATES =
  "/api/v1/buyer/dashboard_reports/rates_distribution_funder";
