import React, { createContext } from "react";

import useAuth from "./hooks/useAuth";
import { AuthContextType } from "../types";

type IAuthProviderProps = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType>({
  authenticated: false,
  loading: true,
});

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const { authenticated, loading } = useAuth();

  return (
    <AuthContext.Provider value={{ loading, authenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
