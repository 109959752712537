import Cookies from "js-cookie";

export const keys = {
  JWT: "buyer_jwt",
  USER_INFO: "buyer_user_info",
  SIDEBAR_EXPANDED: "buyer_sidebar_expanded",
  RAN_TOURS: "buyer_ran_tours",
};

interface CookieOptions {
  expiresIn?: number;
}

const convertSecondsToDays = (expiresIn: number) => expiresIn / 60 / 60 / 24;

const getApplicationDomain = () => `.${window.env.domain}`;

class Cookie {
  static get(key: string) {
    const value = Cookies.get(key);
    const isObject = value && value?.startsWith("{");
    const isBoolean = value === "false" || value === "true";

    return isObject || isBoolean ? JSON.parse(value) : value;
  }

  static set(
    key: string,
    value: string | boolean | any,
    options: CookieOptions = {}
  ) {
    const parsedValue = JSON.stringify(value);

    const { expiresIn } = options;
    const cookiesOptions: Cookies.CookieAttributes = {
      domain: getApplicationDomain(),
    };
    if (expiresIn) cookiesOptions.expires = convertSecondsToDays(expiresIn);

    return Cookies.set(key, parsedValue, cookiesOptions);
  }

  static remove(key: string) {
    return Cookies.remove(key, { domain: getApplicationDomain() });
  }
}

export default Cookie;
