export const DASHBOARD_PATH = '/sacado/dashboards';
export const REPORTS_PATH = '/sacado/relatorios';
export const REPORTS_DETAILS_PATH = '/sacado/relatorios/:id';
export const INVOICES_PATH = '/sacado/duplicatas';
export const INVOICES_HISTORY_PATH = '/sacado/confirmacao_titulos';
export const TRADES_PATH = '/sacado/negociacoes';
export const INVOICE_DETAILS_PATH = '/sacado/duplicatas/:id';
export const VENDORS_PATH = '/sacado/fornecedores';
export const VENDORS_GROUP_PATH = '/sacado/fornecedores/grupos';
export const EXTERNAL_VENDOR_APPROVAL = '/sacado/publico/aprovacao-externa';
export const LIMITS_PATH = '/sacado/limites';

export const DIGITAL_SIGNATURE_TEST_PATH = '/testar_assinatura_digital';
export const BANK_ACCOUNTS_PATH = '/sacado/conta_bancarias';
export const ADDRESSES_PATH = '/sacado/enderecos';
export const MAIN_CONTRACT_PATH = '/sacado/contrato_mae';
export const REGISTRATION_DATA_PATH = '/sacados/edit';
export const SWAP_ORGANIZATION_PATH = '/alternar_organizacao';
export const LOGOUT_PATH = '/sacados/sair';

export const INVOICES_CONFIRM_PATH = `${INVOICES_HISTORY_PATH}/new`;
export const INVOICES_CONFIRM_DECLARATION_PATH = `${INVOICES_HISTORY_PATH}/prepare_declaration`;
export const INVOICES_COMPLETE_CONFIRMATION = `${INVOICES_HISTORY_PATH}/aguardando_continuacao_confirmacao`;
export const INVOICES_MANUAL_INSERT = '/sacado/nota_fiscais/new';
export const INVOICES_SPREADSHEET_INSERT = `${INVOICES_PATH}/batch_upload`;

export const SPA_PATHS: string[] = [
  TRADES_PATH,
  INVOICE_DETAILS_PATH,
  INVOICES_PATH,
  INVOICES_MANUAL_INSERT,
  REPORTS_PATH,
  REPORTS_DETAILS_PATH,
  EXTERNAL_VENDOR_APPROVAL,
  VENDORS_GROUP_PATH,
];
