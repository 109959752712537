import { MouseEvent } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { Typography, Icon } from '@libercapital/react-salti';

import { TourIdentifier } from 'types';
import { dispatchAmplitudeEvent } from 'services/amplitude';

import {
  Button,
  NextButton,
  SkipContainer,
  TooltipBody,
  TooltipFooter,
  TooltipTitle,
} from './styles';

interface TourToolTipProps extends TooltipRenderProps {
  identifier?: TourIdentifier;
}

const TourTooltip: React.FC<TourToolTipProps> = ({
  identifier,
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  size,
}) => {
  const handleNext = (e: MouseEvent<HTMLElement>) => {
    const eventName =
      index + 1 === size
        ? `Buyer ${identifier}: tour finish`
        : `Buyer ${identifier}: tour next step`;

    dispatchAmplitudeEvent(eventName, { index });

    if (continuous) {
      primaryProps.onClick(e);
    } else {
      closeProps.onClick(e);
    }
  };

  const handleBack = (e: MouseEvent<HTMLElement>) => {
    dispatchAmplitudeEvent(`Buyer ${identifier}: tour previous step`, { index });

    backProps.onClick(e);
  };

  const handleSkip = (e: MouseEvent<HTMLElement>) => {
    dispatchAmplitudeEvent(`Buyer ${identifier}: tour skip`, { index, button: 'skip' });

    skipProps.onClick(e);
  };

  return (
    <TooltipBody ref={tooltipProps.ref}>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <Typography color="textSecondary" variant="body2">
        {step.content}
      </Typography>
      <TooltipFooter>
        {step.showSkipButton && index !== size - 1 && (
          <SkipContainer>
            <Button onClick={e => handleSkip(e as MouseEvent<HTMLElement>)}>
              {skipProps.title}
            </Button>
          </SkipContainer>
        )}
        {index > 0 && (
          <Button onClick={e => handleBack(e as MouseEvent<HTMLElement>)}>{backProps.title}</Button>
        )}
        {continuous && (
          <NextButton
            endIcon={<Icon name={index + 1 === size ? 'Checkmark' : 'ArrowRight'} />}
            onClick={e => handleNext(e as MouseEvent<HTMLElement>)}
          >
            {`${primaryProps.title} (${index + 1}/${size})`}
          </NextButton>
        )}
        {!continuous && (
          <NextButton
            endIcon={<Icon name={index + 1 === size ? 'Checkmark' : 'ArrowRight'} />}
            onClick={e => handleNext(e as MouseEvent<HTMLElement>)}
          >
            {`${closeProps.title} (${index + 1}/${size})`}
          </NextButton>
        )}
      </TooltipFooter>
    </TooltipBody>
  );
};

export default TourTooltip;
