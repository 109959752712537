import React, { ReactNode, createContext, useState } from "react";

import { LayoutContextInterface } from "./types";

export const LayoutContext = createContext<LayoutContextInterface>({});

export const LayoutStore = ({ children }: { children: ReactNode }) => {
  const [appbarAction, setAppbarAction] = useState<React.ReactNode>();

  const handleUpdateAppbarAction = (element: React.ReactNode) =>
    setAppbarAction(element);

  return (
    <LayoutContext.Provider
      value={{
        appbar: {
          setActionElement: handleUpdateAppbarAction,
          actionElement: appbarAction,
        },
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
