import {
  useEffect,
  useState,
  ReactNode,
  ReactNodeArray,
  useContext,
  useMemo,
} from "react";
import { AppBar, Banner } from "@libercapital/react-salti";
import moment from "moment";
import Hotjar from "@hotjar/browser";

import { dispatchAmplitudeEvent } from "services/amplitude";
import Cookie, { keys } from "services/cookie";
import { useMobile } from "utils";
import Sidebar from "components/Sidebar";
import { UserInfoContext } from "contexts/UserInfoContext";
import { SystemInfoContext } from "contexts/SystemInfoContext";
import { LayoutContext } from "contexts/LayoutContext";

import LiberLogo from "assets/images/brand/liber-logo.svg";
import {
  Container,
  ContainerWrapper,
  BannerWrapper,
  MessageBannerWrapper,
  Content,
  MessageBannerSpacer,
} from "./styles";
import { BANNER_HEIGHT } from "./constants";

const Layout = ({ children }: { children: ReactNodeArray | ReactNode }) => {
  const isMobile = useMobile();
  const initialSidebarExpanded: boolean =
    Cookie.get(keys.SIDEBAR_EXPANDED) ?? true;
  const siteId = window.env.hotjarSiteId ?? 0;
  const hotjarVersion = 6;

  const {
    loading,
    operator,
    portal,
    anticipationLimitsAvailable,
    loggedAsOperator,
    certifier,
  } = useContext(UserInfoContext);

  const { loggedInAs, generalInfo } = useContext(SystemInfoContext);

  const { appbar: appbarAction } = useContext(LayoutContext);

  const [open, setOpen] = useState<boolean>(initialSidebarExpanded);

  const setSidebarExpandedCookie = () => {
    Cookie.set(keys.SIDEBAR_EXPANDED, open);
  };

  const handleToggle = () => {
    dispatchAmplitudeEvent("buyer_sidebar", {
      option: open ? "Comprimir menu" : "Expandir menu",
    });
    setOpen(!open);
  };

  const { adminEmail, user } = useMemo(() => loggedInAs, [loggedInAs]) || {};

  const message = useMemo(() => {
    if (!generalInfo) return undefined;

    const today = moment();
    const expiration = moment(generalInfo?.expiration, "DD/MM/YYYY");

    return today.isAfter(expiration) ? undefined : generalInfo?.message;
  }, [generalInfo]);

  const { actionElement } = useMemo(() => appbarAction, [appbarAction]) || {};

  const adminBannerOffset = useMemo(
    () => (isMobile || !loggedInAs ? 0 : BANNER_HEIGHT),
    [isMobile, loggedInAs]
  );

  if (!loggedInAs) Hotjar.init(+siteId, hotjarVersion);

  useEffect(() => {
    if (open && isMobile) setOpen(false);
  }, [isMobile]);

  useEffect(() => {
    setSidebarExpandedCookie();
  }, [open]);

  return (
    <>
      {isMobile && (
        <AppBar
          iconName={open ? "Dismiss" : "LineHorizontal3"}
          logo={LiberLogo}
          title="Liber"
          onClick={handleToggle}
          secondaryAction={actionElement}
        />
      )}
      <Sidebar
        open={open}
        handleToggle={handleToggle}
        loading={loading}
        operator={operator}
        portal={portal}
        anticipationLimitsAvailable={anticipationLimitsAvailable}
        loggedAsOperator={loggedAsOperator}
        usesDigitalSignature={certifier === "assiname"}
        marginTop={adminBannerOffset}
      />
      {loggedInAs && !isMobile && (
        <BannerWrapper>
          <Banner color="admin">
            Admin <b>{adminEmail}</b> logado como <b>{user}</b>.
          </Banner>
        </BannerWrapper>
      )}
      <ContainerWrapper sidebarOpen={open} marginTop={adminBannerOffset}>
        {!isMobile && message && (
          <>
            <MessageBannerWrapper>
              <Banner color="info">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<b>Atenção:</b> ${message}`,
                  }}
                />
              </Banner>
            </MessageBannerWrapper>
            <MessageBannerSpacer />
          </>
        )}
        <Container>
          {isMobile && (
            <>
              {loggedInAs && (
                <Banner color="admin">
                  Admin <b>{adminEmail}</b> logado como <b>{user}</b>.
                </Banner>
              )}
              {message && (
                <Banner color="info">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<b>Atenção:</b> ${message}`,
                    }}
                  />
                </Banner>
              )}
            </>
          )}
          <Content>{children}</Content>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default Layout;
