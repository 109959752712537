import { AxiosError, AxiosResponse, AxiosRequestConfig, Axios } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

import {
  withId,
  sortingParam,
  batchesPaginationParam,
  batchesSortingParam,
  nestFilters,
  withToken,
} from "utils";
import {
  TradesResponse,
  TradesSummaryResponse,
  InvoiceResponse,
  InvoicesResponse,
  InvoicesGroupedResponse,
  InvoiceActionResponse,
  TradesFiltersResponse,
  UserInfoResponse,
  ResponseType,
  TradesSortingInterface,
  ReportFileType,
  EditableInvoiceFields,
  InvoicesSortingInterface,
  InvoicesVendorsSummaryResponse,
  InvoicesFundersSummaryResponse,
  InvoicesVendorsSummarySortingInterface,
  InvoicesFundersSummarySortingInterface,
  BatchesPaginationType,
  BatchesSortingType,
  CreateInvoicesResponse,
  SystemInfoResponse,
  ReportsListResponse,
  ReportsSortingInterface,
  ReportRequest,
  ReportFetchResponse,
  ReportDownloadResponse,
  ReportFields,
  IVendorsPublicInfoResponse,
  VendorsGroupResponse,
  RateChartResponse,
  CurrentWalletChartResponse,
  FunderTotalOperationsChartResponse,
  WeightedTermsChartResponse,
  WeightedRatesChartResponse,
  VendorTpvChartResponse,
  VendorsCurrentWalletChartResponse,
  VendorsOutlookChartResponse,
  VendorsWeightedAgreedRateChartResponse,
  VendorsPenetrationTotalValueChartResponse,
  VendorsWeightedTermChartResponse,
  OperationsSummaryResponse,
} from "types";
import api from "./instance";
import { getAuthorizationHeader, checkAuthorization } from "./auth";
import {
  TRADES_INDEX_ROUTE,
  INVOICES_GROUPED_INDEX_ROUTE,
  TRADES_FILTERS_ROUTE,
  INVOICE_SHOW_ROUTE,
  INVOICE_PENDING_ROUTE,
  INVOICE_BLOCK_ROUTE,
  INVOICE_UNBLOCK_ROUTE,
  USER_INFO_ROUTE,
  TRADES_REPORT_ROUTE,
  INVOICE_EDIT_ROUTE,
  INVOICES_INDEX_ROUTE,
  TRADES_SUMMARY_ROUTE,
  INVOICES_FUNDERS_SUMMARY_ROUTE,
  INVOICES_VENDORS_SUMMARY_ROUTE,
  INVOICES_REPORT_ROUTE,
  CREATE_INVOICES_ROUTE,
  SYSTEM_INFO_ROUTE,
  REPORTS_INFO_ROUTE,
  REPORTS_CREATE_ROUTE,
  REPORTS_INDEX_ROUTE,
  REPORTS_DOWNLOAD_ROUTE,
  VENDORS_PUBLIC_INFO_ROUTE,
  UPDATE_VENDOR_STATUS_ROUTE,
  VENDORS_GROUP_ROUTE,
  VENDORS_GROUP_UPDATE_ROUTE,
  RATE_CHART_ROUTE,
  CURRENT_WALLET_CHART_ROUTE,
  FUNDER_TOTAL_OPERATIONS_CHART_ROUTE,
  WEIGHTED_TERMS_CHART_ROUTE,
  WEIGHTED_RATES_CHART_ROUTE,
  VENDORS_TPV_CHART_ROUTE,
  VENDORS_CURRENT_WALLET_CHART_ROUTE,
  VENDORS_OUTLOOK_CHART_ROUTE,
  VENDORS_WEIGHTED_AGREED_RATE_ROUTE,
  VENDORS_PENETRATION_TOTAL_VALUE_ROUTE,
  VENDORS_WEIGHTED_TERM_ROUTE,
  OPERATIONS_SUMMARY_ROUTE,
} from "./routes";

/* START - Gerenciamento de erros. */
export const handleError = (e: AxiosError) => {
  const { data, status: statusCode } = e.response || {};
  const response = { status: false, data, statusCode };
  if (e.response) {
    if (e.response.status === 401) return response;
    console.log("Handle other error status.");
  } else {
    console.log("Handle generic errors.");
  }
  return response;
};
/* END */

/* START - Gerenciamento de interceptadores. */
api.interceptors.request.use(
  (config: AxiosRequestConfig) => ({
    ...config,
    headers: {
      authorization: getAuthorizationHeader(),
    },
  }),
  (error: AxiosError) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers["content-type"] === "application/json; charset=utf-8"
    ) {
      response.data = camelizeKeys(response.data as Record<string, unknown>);
    }
    return response;
  },
  (error: AxiosError) => {
    checkAuthorization(error);
    return Promise.reject(error);
  }
);
/* END */

export const requestHandler = async <T extends ResponseType>(
  method: Axios["get"] | Axios["post"],
  path: string,
  body?: unknown
) => {
  try {
    const {
      data: { data },
    } = await method<T>(path, decamelizeKeys(body as Record<string, unknown>));
    return { status: true, data } as T;
  } catch (error) {
    return handleError(error as AxiosError) as T;
  }
};

export const formRequestHandler = async <T extends ResponseType>(
  method: Axios["post"],
  path: string,
  formData: FormData
) => {
  try {
    const {
      data: { data },
    } = await method<T>(path, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return { status: true, data } as T;
  } catch (error) {
    return handleError(error as AxiosError) as T;
  }
};

/* START - Chamadas para a API. */
export async function fetchTrades(
  sorting?: TradesSortingInterface,
  page = 1,
  perPage = 25,
  filteringParam?: { [key: string]: unknown }
) {
  return requestHandler<TradesResponse>(api.get, TRADES_INDEX_ROUTE, {
    params: { ...sortingParam(sorting), ...filteringParam, page, perPage },
  });
}

export async function fetchTradesSummary(filteringParam?: {
  [key: string]: unknown;
}) {
  return requestHandler<TradesSummaryResponse>(api.get, TRADES_SUMMARY_ROUTE, {
    params: { ...filteringParam },
  });
}

export async function fetchTradesReport(
  fileFormat: ReportFileType,
  sorting?: TradesSortingInterface,
  filteringParam?: { [key: string]: unknown }
) {
  return requestHandler<ResponseType>(api.get, TRADES_REPORT_ROUTE, {
    params: { ...sortingParam(sorting), ...filteringParam, fileFormat },
  });
}

export async function fetchTradesFilters() {
  return requestHandler<TradesFiltersResponse>(api.get, TRADES_FILTERS_ROUTE);
}

export async function fetchInvoice(id: number | string) {
  return requestHandler<InvoiceResponse>(
    api.get,
    withId(INVOICE_SHOW_ROUTE, id)
  );
}

export async function fetchInvoices(
  sorting?: InvoicesSortingInterface,
  page = 1,
  perPage = 10,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoicesResponse>(api.get, INVOICES_INDEX_ROUTE, {
    params: { ...sortingParam(sorting), ...filteringParams, page, perPage },
  });
}

export async function fetchInvoicesReport(
  fileFormat: ReportFileType,
  sorting?: InvoicesSortingInterface,
  filteringParam?: { [key: string]: unknown }
) {
  return requestHandler<ResponseType>(api.get, INVOICES_REPORT_ROUTE, {
    params: { ...sortingParam(sorting), ...filteringParam, fileFormat },
  });
}

export async function fetchGroupedInvoices(
  pagination: BatchesPaginationType,
  sorting: BatchesSortingType,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoicesGroupedResponse>(
    api.get,
    INVOICES_GROUPED_INDEX_ROUTE,
    {
      params: {
        ...batchesPaginationParam(pagination),
        ...batchesSortingParam(sorting),
        ...filteringParams,
      },
    }
  );
}

export async function setPendingInvoices(
  ids: (number | string)[],
  pendencyReason: string,
  pendencyComment: string,
  all = false,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoiceActionResponse>(
    api.post,
    INVOICE_PENDING_ROUTE,
    {
      ids: all ? undefined : ids,
      pendencyReason: Number(pendencyReason),
      pendencyComment,
      all,
      ...nestFilters(filteringParams),
    }
  );
}

export async function editInvoice(
  id: number | string,
  params: EditableInvoiceFields
) {
  return requestHandler<InvoiceActionResponse>(
    api.put,
    withId(INVOICE_EDIT_ROUTE, id),
    params
  );
}

export async function blockInvoices(
  ids: (number | string)[],
  blockReason: string,
  blockComment: string,
  all = false,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoiceActionResponse>(api.post, INVOICE_BLOCK_ROUTE, {
    ids: all ? undefined : ids,
    blockReason: Number(blockReason),
    blockComment,
    all,
    ...nestFilters(filteringParams),
  });
}

export async function unblockInvoices(
  ids: (number | string)[],
  all = false,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoiceActionResponse>(
    api.post,
    INVOICE_UNBLOCK_ROUTE,
    {
      ids: all ? undefined : ids,
      all,
      ...nestFilters(filteringParams),
    }
  );
}

export async function fetchUserInfo() {
  return requestHandler<UserInfoResponse>(api.get, USER_INFO_ROUTE);
}

export async function fetchInvoicesFundersSummary(
  page = 1,
  perPage = 25,
  sorting?: InvoicesFundersSummarySortingInterface,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoicesFundersSummaryResponse>(
    api.get,
    INVOICES_FUNDERS_SUMMARY_ROUTE,
    {
      params: { ...sortingParam(sorting), ...filteringParams, page, perPage },
    }
  );
}

export async function fetchInvoicesVendorsSummary(
  page = 1,
  perPage = 25,
  sorting?: InvoicesVendorsSummarySortingInterface,
  filteringParams?: { [key: string]: unknown }
) {
  return requestHandler<InvoicesVendorsSummaryResponse>(
    api.get,
    INVOICES_VENDORS_SUMMARY_ROUTE,
    {
      params: { ...sortingParam(sorting), ...filteringParams, page, perPage },
    }
  );
}

export async function createInvoices(formData: FormData) {
  return formRequestHandler<CreateInvoicesResponse>(
    api.post,
    CREATE_INVOICES_ROUTE,
    formData
  );
}

export async function fetchSystemInfo() {
  return requestHandler<SystemInfoResponse>(api.get, SYSTEM_INFO_ROUTE);
}

export async function fetchReportsList(
  sorting?: ReportsSortingInterface,
  page = 1,
  perPage = 25,
  filteringParam?: { [key: string]: unknown }
) {
  return requestHandler<ReportsListResponse>(api.get, REPORTS_INDEX_ROUTE, {
    params: { ...sortingParam(sorting), ...filteringParam, page, perPage },
  });
}

export async function fetchReportByID(id: string) {
  return requestHandler<ReportFetchResponse>(
    api.get,
    withId(REPORTS_INFO_ROUTE, id)
  );
}

export async function fetchReportURLDownloadByID(id: string) {
  return requestHandler<ReportDownloadResponse>(
    api.get,
    withId(REPORTS_DOWNLOAD_ROUTE, id)
  );
}

export async function createReport(data: ReportRequest<ReportFields>) {
  return requestHandler<ResponseType>(api.post, REPORTS_CREATE_ROUTE, data);
}

export async function fetchVendorsPublicInfo(token: string) {
  return requestHandler<IVendorsPublicInfoResponse>(
    api.get,
    withToken(VENDORS_PUBLIC_INFO_ROUTE, token)
  );
}

export async function updateVendorStatus(token: string, body: unknown) {
  return requestHandler<IVendorsPublicInfoResponse>(
    api.post,
    withToken(UPDATE_VENDOR_STATUS_ROUTE, token),
    body
  );
}

export async function fetchVendorsGroup(page = 1, perPage = 25) {
  return requestHandler<VendorsGroupResponse>(api.get, VENDORS_GROUP_ROUTE, {
    params: { page, perPage },
  });
}

export async function updateVendorsGroup(id: number | string, body: unknown) {
  return requestHandler<VendorsGroupResponse>(
    api.put,
    withId(VENDORS_GROUP_UPDATE_ROUTE, id),
    body
  );
}

export async function deleteVendorsGroup(id: number | string) {
  return requestHandler<VendorsGroupResponse>(
    api.delete,
    withId(VENDORS_GROUP_UPDATE_ROUTE, id)
  );
}

export async function fetchRateChart(filteringParam?: string) {
  return requestHandler<RateChartResponse>(
    api.get,
    `${RATE_CHART_ROUTE}?${filteringParam}`
  );
}

export async function fetchCurrentWalletChart() {
  return requestHandler<CurrentWalletChartResponse>(
    api.get,
    CURRENT_WALLET_CHART_ROUTE
  );
}

export async function fetchFunderTotalOperationsChart() {
  return requestHandler<FunderTotalOperationsChartResponse>(
    api.get,
    FUNDER_TOTAL_OPERATIONS_CHART_ROUTE
  );
}

export async function fetchWeightedTermsChart() {
  return requestHandler<WeightedTermsChartResponse>(
    api.get,
    WEIGHTED_TERMS_CHART_ROUTE
  );
}

export async function fetchWeightedRatesChart() {
  return requestHandler<WeightedRatesChartResponse>(
    api.get,
    WEIGHTED_RATES_CHART_ROUTE
  );
}

export async function fetchVendorTpvChart() {
  return requestHandler<VendorTpvChartResponse>(
    api.get,
    VENDORS_TPV_CHART_ROUTE
  );
}

export async function fetchVendorsCurrentWalletChart() {
  return requestHandler<VendorsCurrentWalletChartResponse>(
    api.get,
    VENDORS_CURRENT_WALLET_CHART_ROUTE
  );
}

export async function fetchVendorsOutlookChart() {
  return requestHandler<VendorsOutlookChartResponse>(
    api.get,
    VENDORS_OUTLOOK_CHART_ROUTE
  );
}

export async function fetchVendorsWeightedAgreedRateChart(
  filteringParam?: string
) {
  return requestHandler<VendorsWeightedAgreedRateChartResponse>(
    api.get,
    `${VENDORS_WEIGHTED_AGREED_RATE_ROUTE}?${filteringParam}`
  );
}

export async function fetchOperationsSummary() {
  return requestHandler<OperationsSummaryResponse>(
    api.get,
    OPERATIONS_SUMMARY_ROUTE
  );
}

export async function fetchVendorsPenetrationTotalValueChart(
  filteringParam?: string
) {
  return requestHandler<VendorsPenetrationTotalValueChartResponse>(
    api.get,
    `${VENDORS_PENETRATION_TOTAL_VALUE_ROUTE}?${filteringParam}`
  );
}

export async function fetchVendorWeightedTermChart(filteringParam?: string) {
  return requestHandler<VendorsWeightedTermChartResponse>(
    api.get,
    `${VENDORS_WEIGHTED_TERM_ROUTE}?${filteringParam}`
  );
}

/* END */
