import React from "react";

import { SystemInfoProvider } from "./SystemInfoContext";
import { UserInfoProvider } from "./UserInfoContext";

type IAppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: IAppProviderProps) => (
  <SystemInfoProvider>
    <UserInfoProvider>{children}</UserInfoProvider>
  </SystemInfoProvider>
);
