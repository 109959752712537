import { doc, getDoc, setDoc, updateDoc, FieldValue } from 'firebase/firestore';
import { signInWithCustomToken } from 'firebase/auth';

import { auth, firestoreDb } from './instance';

export const authenticate = async (token: string) => {
  if (!auth) return { status: false, uid: undefined };

  try {
    const {
      user: { uid },
    } = await signInWithCustomToken(auth, token);

    return { status: true, uid };
  } catch (error) {
    return { status: false, uid: undefined, error };
  }
};

export const getDocument = async (collection: string) => {
  if (!firestoreDb) return { status: false };

  const { uid } = auth?.currentUser || {};
  try {
    const documentReference = doc(firestoreDb, `${collection}/${uid}`);
    const documentSnapshot = await getDoc(documentReference);
    const data = documentSnapshot.data() ?? undefined;
    return { status: true, data };
  } catch (error) {
    return { status: false, error };
  }
};

export const setDocument = async (collection: string, payload: Record<string, FieldValue>) => {
  if (!firestoreDb) return { status: false };

  const { uid } = auth?.currentUser || {};
  try {
    const documentReference = doc(firestoreDb, `${collection}/${uid}`);
    const documentSnapshot = await getDoc(documentReference);

    if (documentSnapshot.exists()) {
      await updateDoc(documentReference, payload);
    } else {
      await setDoc(documentReference, payload);
    }

    return { status: true };
  } catch (error) {
    return { status: false, error };
  }
};
