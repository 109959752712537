import React, { useRef, useState, useMemo } from 'react';
import { ListItem } from '@libercapital/react-salti';
import { IconName } from '@libercapital/react-salti/build/Icon/Icon.types';

import { SidebarItemProps } from '../types';

import Menu from './Menu';

const Item = ({
  label,
  icon,
  path = '',
  pathname,
  open,
  subPaths,
  navigate,
  routePatterns,
}: SidebarItemProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const itemRef = useRef<HTMLLIElement>();

  const secondaryIconName = useMemo<IconName>(
    () => (menuOpen ? 'ChevronLeft' : 'ChevronRight'),
    [menuOpen],
  );

  const selected = useMemo<boolean>(
    () => routePatterns.some(pattern => pattern.test(pathname)),
    [pathname],
  );

  const hasSubpaths = useMemo<boolean>(() => Boolean(subPaths?.length > 0), [subPaths]);

  return (
    <>
      <ListItem
        key={label}
        variant="icon"
        iconName={icon}
        primaryText={label}
        collapsed={!open}
        onClick={() => (hasSubpaths ? setMenuOpen(true) : navigate(path, label))}
        selected={selected}
        active={menuOpen}
        ref={itemRef as React.Ref<HTMLLIElement>}
        secondaryIconName={hasSubpaths ? secondaryIconName : undefined}
      />
      <Menu
        subPaths={subPaths}
        open={menuOpen}
        setOpen={value => setMenuOpen(value)}
        anchorEl={itemRef.current as HTMLLIElement}
        pathname={pathname}
        navigate={navigate}
      />
    </>
  );
};
export default Item;
