import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { camelizeKeys } from "humps";

import { UserInfo as UserInfoInterface, JWTUserInfo } from "types";
import { fetchUserInfo } from "services/api";
import Cookie, { keys } from "services/cookie";
import { authenticate } from "services/firebase";

const decodedJwt = () => {
  try {
    return camelizeKeys(jwtDecode(Cookie.get(keys.JWT))) as JWTUserInfo;
  } catch {
    return undefined;
  }
};

export default function useUserInfo(fetchUserData = true) {
  const [loading, setLoading] = useState(true);
  const [firebaseLoading, setFirebaseLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfoInterface>();
  const [jwtData, setJwtData] = useState<JWTUserInfo>();

  const handleSetCookie = (userInfoPayload: UserInfoInterface) =>
    Cookie.set(keys.USER_INFO, userInfoPayload, {
      expiresIn: 3600 /* 1 hour */,
    });

  const handleAuthenticate = async () => {
    await authenticate(userInfo?.firebaseCustomToken || "");
    setFirebaseLoading(false);
  };

  useEffect(() => {
    const savedUserInfo: UserInfoInterface | undefined = Cookie.get(
      keys.USER_INFO
    );

    const jwtDecodedData = decodedJwt() || {};
    const { operatorId: userInfoOperatorId } = savedUserInfo || {};
    setJwtData(jwtDecodedData);

    if (savedUserInfo && jwtDecodedData.operatorId === userInfoOperatorId) {
      const [currentSubdomain] = window.location.hostname.split(".");
      const { portal: { subdomain: savedSubdomain } = {} } = savedUserInfo;

      if (currentSubdomain === savedSubdomain) {
        setUserInfo(savedUserInfo);
        return;
      }
    }

    if (fetchUserData) {
      fetchUserInfo().then(({ status, data }) => {
        if (status) {
          setUserInfo(data);
          handleSetCookie(data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userInfo !== undefined) {
      setLoading(false);
      handleAuthenticate();
    }
  }, [userInfo]);

  return {
    loading,
    firebaseLoading,
    ...userInfo,
    jwtData,
  };
}
