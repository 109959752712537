import amplitude from 'amplitude-js';

export const setupAmplitude = () => {
  const key = window.env.amplitudeApiKey;
  if (key) amplitude.getInstance().init(key);
};

export const dispatchAmplitudeEvent = (event: string, additionalParameters: unknown = {}) => {
  const { apiKey } = amplitude.getInstance().options as { apiKey: string };
  if (apiKey) amplitude.getInstance().logEvent(event, additionalParameters);
};
