import { useLocation, useHistory } from 'react-router-dom';
import { Sidebar as RawSidebar } from '@libercapital/react-salti';

import { dispatchAmplitudeEvent } from 'services/amplitude';
import Navigation from 'routes/Navigation';
import LiberLogo from 'assets/images/brand/liber-logo.svg';
import LiberLogoSmall from 'assets/images/brand/liber-logo-small.svg';

import Item from './elements/Item';
import { routes, userSettings } from './constants';
import { SidebarComponentProps } from './types';

const Sidebar = ({
  open,
  handleToggle,
  operator,
  portal,
  loading,
  anticipationLimitsAvailable,
  loggedAsOperator,
  usesDigitalSignature,
  marginTop,
}: SidebarComponentProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const navigate = (path: string, label: string) => {
    dispatchAmplitudeEvent('buyer_sidebar', { option: label });
    Navigation.navigate(path, history);
  };

  return (
    <RawSidebar
      marginTop={marginTop}
      logo={loading ? undefined : portal?.logoUrl || LiberLogo}
      smallLogo={loading ? undefined : portal?.logoUrl || LiberLogoSmall}
      name={portal?.name || ''}
      open={open}
      handleToggle={handleToggle}
      userSettings={{
        ...userSettings({ navigate, loggedAsOperator, usesDigitalSignature, pathname }),
        primaryText: loading ? '' : operator || 'Operador',
        secondaryText: loading ? '' : portal?.name,
      }}
    >
      {routes({ anticipationLimitsAvailable, portal }).map(
        ({ label, icon, path, subPaths, routePatterns }) => (
          <Item
            key={label}
            open={open}
            pathname={pathname}
            label={label}
            icon={icon}
            path={path}
            subPaths={subPaths}
            navigate={navigate}
            routePatterns={routePatterns}
          />
        ),
      )}
    </RawSidebar>
  );
};

export default Sidebar;
