import { AxiosError, AxiosResponse } from "axios";

import Cookie, { keys } from "services/cookie";
import api from "./instance";
import { ISignOutResponse } from "types";

const { ignoreLogin } = window.env;

export const getAuthorizationHeader = () => `Bearer ${Cookie.get(keys.JWT)}`;

export const checkAuthorization = (e: AxiosError) => {
  if (ignoreLogin === "true") {
    return;
  }

  if (e.response?.status === 401) {
    Cookie.remove(keys.JWT);
    Cookie.remove(keys.USER_INFO);
    window.location.assign(
      `${window.env.monolithDomain}${window.env.loginPath}`
    );
  }
};

const redirect = (response: AxiosResponse<ISignOutResponse>) => {
  Cookie.remove(keys.JWT);
  Cookie.remove(keys.USER_INFO);
  window.location.href = response.data.data.redirectUrl;
};

export const signout = () => {
  api
    .delete<ISignOutResponse>("/api/v1/operator/sign_out", {
      withCredentials: true,
    })
    .then(redirect);
};
