export interface WindowEnv {
  [key: string]: string | undefined;
}

declare global {
  interface Window {
    env: WindowEnv;
  }
}

function getEnvValueOrPlaceholder(name: string): string | undefined {
  if (process.env.NODE_ENV === 'production') {
    return name;
  }

  return process.env[name];
}

function setupEnv() {
  window.env = {
    apiHost: getEnvValueOrPlaceholder('REACT_APP_API_HOST'),
    loginPath: getEnvValueOrPlaceholder('REACT_APP_LOGIN_PATH'),
    ignoreLogin: getEnvValueOrPlaceholder('REACT_APP_IGNORE_LOGIN'),
    monolithDomain: getEnvValueOrPlaceholder('REACT_APP_MONOLITH_DOMAIN'),
    domain: getEnvValueOrPlaceholder('REACT_APP_DOMAIN'),
    websocketUrl: getEnvValueOrPlaceholder('REACT_APP_WEBSOCKET_URL'),
    amplitudeApiKey: getEnvValueOrPlaceholder('REACT_APP_AMPLITUDE_API_KEY'),
    environment: getEnvValueOrPlaceholder('REACT_APP_ENVIRONMENT'),
    releaseVersion: getEnvValueOrPlaceholder('REACT_APP_RELEASE_VERSION'),
    firebaseApiKey: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_API_KEY'),
    firebaseAuthDomain: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    firebaseProjectId: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_PROJECT_ID'),
    firebaseStorageBucket: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_STORAGE_BUCKET'),
    firebaseMessagingSenderId: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
    firebaseAppId: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_APP_ID'),
    firebaseMeasurementId: getEnvValueOrPlaceholder('REACT_APP_FIREBASE_MEASUREMENT_ID'),
    datadogEnvironment: getEnvValueOrPlaceholder('REACT_APP_DATADOG_ENVIRONMENT'),
    datadogEnabled: getEnvValueOrPlaceholder('REACT_APP_DATADOG_ENABLED'),
    datadogApplicationId: getEnvValueOrPlaceholder('REACT_APP_DATADOG_APPLICATION_ID'),
    datadogClientToken: getEnvValueOrPlaceholder('REACT_APP_DATADOG_CLIENT_TOKEN'),
    datadogService: getEnvValueOrPlaceholder('REACT_APP_DATADOG_SERVICE'),
    hotjarSiteId: getEnvValueOrPlaceholder('REACT_APP_HOTJAR_SITE_ID'),
  };
}

setupEnv();
