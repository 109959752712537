import React, { useContext, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { CircularProgress } from "@libercapital/react-salti";

import Layout from "components/Layout";
import { AuthContext } from "contexts/AuthContext";
import { AppProvider } from "contexts/AppProvider";
import { LayoutStore } from "contexts/LayoutContext";
import { ExternalVendorApprovalProvider } from "contexts/externalVendorApprovalContext";
import {
  DASHBOARD_PATH,
  INVOICES_PATH,
  INVOICES_HISTORY_PATH,
  INVOICE_DETAILS_PATH,
  INVOICES_MANUAL_INSERT,
  TRADES_PATH,
  VENDORS_PATH,
  LIMITS_PATH,
  REPORTS_PATH,
  EXTERNAL_VENDOR_APPROVAL,
  VENDORS_GROUP_PATH,
} from "./paths";

const Dashboard = lazy(() => import("pages/dashboard"));
const Invoices = lazy(() => import("pages/invoices"));
const InvoicesHistory = lazy(() => import("pages/invoicesHistory"));
const InvoiceDetails = lazy(() => import("pages/invoiceDetails"));
const CreateInvoices = lazy(() => import("pages/createInvoices"));
const Limits = lazy(() => import("pages/limits"));
const Trades = lazy(() => import("pages/trades"));
const Vendors = lazy(() => import("pages/vendors"));
const VendorsGroup = lazy(() => import("pages/vendorsGroup"));
const Reports = lazy(() => import("pages/reports"));
const ExternalVendorApproval = lazy(
  () => import("pages/externalVendorApproval")
);

const { ignoreLogin } = window.env;

export interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean;
  component?: React.FC;
  withLayout?: boolean;
}

const CustomRoute: React.FunctionComponent<CustomRouteProps> = ({
  isPrivate = true,
  exact = false,
  path,
  component,
  withLayout = true,
}) => {
  const { loading, authenticated } = useContext(AuthContext);

  if (isPrivate && !loading && !authenticated) {
    if (!(ignoreLogin === "true")) {
      window.location.assign(
        `${window.env.monolithDomain}${window.env.loginPath}`
      );
    }
  }

  return withLayout ? (
    <AppProvider>
      <Layout>
        <Route exact={exact} path={path} component={component} />
      </Layout>
    </AppProvider>
  ) : (
    <Route exact={exact} path={path} component={component} />
  );
};

const Routes = () => (
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <LayoutStore>
        <Suspense fallback={<CircularProgress />}>
          <Switch>
            <CustomRoute
              path={INVOICE_DETAILS_PATH}
              component={InvoiceDetails}
            />
            <CustomRoute
              path={INVOICES_HISTORY_PATH}
              component={InvoicesHistory}
            />
            <CustomRoute
              path={INVOICES_MANUAL_INSERT}
              component={CreateInvoices}
            />
            <CustomRoute path={INVOICES_PATH} component={Invoices} />
            <CustomRoute path={TRADES_PATH} component={Trades} />
            <CustomRoute path={VENDORS_PATH} component={Vendors} exact />
            <CustomRoute
              path={VENDORS_GROUP_PATH}
              component={VendorsGroup}
              exact
            />
            <CustomRoute path={LIMITS_PATH} component={Limits} />
            <CustomRoute path={DASHBOARD_PATH} component={Dashboard} />
            <CustomRoute path={REPORTS_PATH} component={Reports} />
            <CustomRoute
              path={EXTERNAL_VENDOR_APPROVAL}
              component={() => (
                <ExternalVendorApprovalProvider>
                  <ExternalVendorApproval />
                </ExternalVendorApprovalProvider>
              )}
              isPrivate={false}
              withLayout={false}
            />
          </Switch>
        </Suspense>
      </LayoutStore>
    </QueryParamProvider>
  </Router>
);

export default Routes;
