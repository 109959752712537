import { Menu as RawMenu, MenuItem } from '@libercapital/react-salti';

import { SidebarMenuProps } from '../types';

const Menu = ({ anchorEl, open, setOpen, subPaths, pathname, navigate }: SidebarMenuProps) => (
  <RawMenu position="right" anchorEl={anchorEl} open={open} onClose={() => setOpen(false)}>
    <>
      {subPaths.map(({ path, label, routePatterns }) => (
        <MenuItem
          key={path}
          selected={routePatterns.some(pattern => pattern.test(pathname))}
          onClick={() => navigate(path, label)}
        >
          {label}
        </MenuItem>
      ))}
    </>
  </RawMenu>
);

export default Menu;
