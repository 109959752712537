import moment from 'moment';

import { DateGroupOption } from 'types';

export const SHOW_ALL = 'Exibir tudo';
export const ALL_NOT_EXPIRED = 'Todos não vencidos';
export const EXPIRES_TODAY = 'Vence hoje';
export const EXPIRES_THIS_WEEK = 'Vence esta semana';
export const EXPIRES_THIS_MONTH = 'Vence este mês';
export const EXPIRES_THIS_QUARTER = 'Vence este trimestre';
export const ALL_EXPIRED = 'Todos vencidos';
export const EXPIRED_THIS_WEEK = 'Venceu esta semana';
export const EXPIRED_THIS_MONTH = 'Venceu este mês';
export const EXPIRED_THIS_QUARTER = 'Venceu este trimestre';
export const SETTLES_TODAY = 'Liquidam hoje';
export const SETTLES_THIS_WEEK = 'Liquidam esta semana';
export const SETTLES_THIS_MONTH = 'Liquidam este mês';
export const SETTLES_THIS_QUARTER = 'Liquidam este trimestre';
export const SETTLED_THIS_WEEK = 'Liquidaram esta semana';
export const SETTLED_THIS_MONTH = 'Liquidaram este mês';
export const SETTLED_THIS_QUARTER = 'Liquidaram este trimestre';
export const TRADED_TODAY = 'Hoje';
export const TRADED_THIS_WEEK = 'Esta semana';
export const TRADED_THIS_MONTH = 'Este mês';
export const TRADED_THIS_QUARTER = 'Este trimestre';
export const CREATED_TODAY = 'Hoje';
export const CREATED_THIS_WEEK = 'Esta semana';
export const CREATED_THIS_MONTH = 'Este mês';
export const CREATED_THIS_QUARTER = 'Este trimestre';
export const CREATED_YESTERDAY = 'Ontem';
export const CREATED_LAST_WEEK = 'Semana passada';
export const CREATED_LAST_MONTH = 'Mês passado';
export const CREATED_LAST_QUARTER = 'Trimestre passado';
export const LAST_30_DAYS = 'Últimos 30 dias';
export const LAST_60_DAYS = 'Últimos 60 dias';
export const LAST_90_DAYS = 'Últimos 90 dias';
export const LAST_180_DAYS = 'Últimos 180 dias';
export const LAST_365_DAYS = 'Últimos 365 dias';

export const getIntervalDays = () => ({
  todayStart: moment().startOf('day'),
  todayEnd: moment().endOf('day'),
  yesterdayStart: moment().subtract(1, 'days').startOf('day'),
  yesterdayEnd: moment().subtract(1, 'days').endOf('day'),
  weekStart: moment().startOf('week'),
  weekEnd: moment().endOf('week'),
  lastWeekStart: moment().subtract(1, 'weeks').startOf('isoWeek'),
  lastWeekEnd: moment().subtract(1, 'weeks').endOf('isoWeek'),
  monthStart: moment().startOf('month'),
  monthEnd: moment().endOf('month'),
  lastMonthStart: moment().subtract(1, 'months').startOf('month'),
  lastMonthEnd: moment().subtract(1, 'months').endOf('month'),
  quarterStart: moment().startOf('quarter'),
  quarterEnd: moment().endOf('quarter'),
  lastQuarterStart: moment().subtract(1, 'quarters').startOf('quarter'),
  lastQuarterEnd: moment().subtract(1, 'quarters').endOf('quarter'),
  interval30Start: moment().subtract(30, 'days').startOf('day'),
  interval30End: moment().subtract(30, 'days').endOf('day'),
  interval60Start: moment().subtract(60, 'days').startOf('day'),
  interval60End: moment().subtract(60, 'days').endOf('day'),
  interval90Start: moment().subtract(90, 'days').startOf('day'),
  interval90End: moment().subtract(90, 'days').endOf('day'),
  interval180Start: moment().subtract(180, 'days').startOf('day'),
  interval180End: moment().subtract(180, 'days').endOf('day'),
  interval365Start: moment().subtract(365, 'days').startOf('day'),
  interval365End: moment().subtract(365, 'days').endOf('day'),
});

const {
  todayStart,
  todayEnd,
  yesterdayStart,
  yesterdayEnd,
  weekStart,
  weekEnd,
  lastWeekStart,
  lastWeekEnd,
  monthStart,
  monthEnd,
  lastMonthStart,
  lastMonthEnd,
  quarterStart,
  quarterEnd,
  lastQuarterStart,
  lastQuarterEnd,
  interval30Start,
  interval60Start,
  interval90Start,
  interval180Start,
  interval365Start,
} = getIntervalDays();

export const EXPIRES_AT_OPTIONS: DateGroupOption[] = [
  {
    label: undefined,
    options: [{ startDate: undefined, endDate: undefined, label: SHOW_ALL }],
  },
  {
    label: 'À VENCER',
    options: [
      { startDate: todayStart, endDate: undefined, label: ALL_NOT_EXPIRED },
      { startDate: todayStart, endDate: todayEnd, label: EXPIRES_TODAY },
      { startDate: todayStart, endDate: weekEnd, label: EXPIRES_THIS_WEEK },
      { startDate: todayStart, endDate: monthEnd, label: EXPIRES_THIS_MONTH },
      { startDate: todayStart, endDate: quarterEnd, label: EXPIRES_THIS_QUARTER },
    ],
  },
  {
    label: 'VENCIDOS',
    options: [
      { startDate: undefined, endDate: todayStart, label: ALL_EXPIRED },
      { startDate: weekStart, endDate: todayStart, label: EXPIRED_THIS_WEEK },
      { startDate: monthStart, endDate: todayStart, label: EXPIRED_THIS_MONTH },
      { startDate: quarterStart, endDate: todayStart, label: EXPIRED_THIS_QUARTER },
    ],
  },
];

export const EXPECTED_PAYMENT_AT_OPTIONS: DateGroupOption[] = [
  {
    label: undefined,
    options: [{ startDate: undefined, endDate: undefined, label: SHOW_ALL }],
  },
  {
    label: 'À LIQUIDAR',
    options: [
      { startDate: todayStart, endDate: todayEnd, label: SETTLES_TODAY },
      { startDate: todayStart, endDate: weekEnd, label: SETTLES_THIS_WEEK },
      { startDate: todayStart, endDate: monthEnd, label: SETTLES_THIS_MONTH },
      { startDate: todayStart, endDate: quarterEnd, label: SETTLES_THIS_QUARTER },
    ],
  },
  {
    label: 'LIQUIDADOS',
    options: [
      { startDate: weekStart, endDate: todayStart, label: SETTLED_THIS_WEEK },
      { startDate: monthStart, endDate: todayStart, label: SETTLED_THIS_MONTH },
      { startDate: quarterStart, endDate: todayStart, label: SETTLED_THIS_QUARTER },
    ],
  },
];

export const FINISHED_AT_OPTIONS: DateGroupOption[] = [
  {
    label: undefined,
    options: [{ startDate: undefined, endDate: undefined, label: SHOW_ALL }],
  },
  {
    label: 'FINALIZADAS',
    options: [
      { startDate: todayStart, endDate: todayEnd, label: TRADED_TODAY },
      { startDate: weekStart, endDate: weekEnd, label: TRADED_THIS_WEEK },
      { startDate: monthStart, endDate: monthEnd, label: TRADED_THIS_MONTH },
      { startDate: quarterStart, endDate: quarterEnd, label: TRADED_THIS_QUARTER },
    ],
  },
];

export const NEGOTIATION_DATE_OPTIONS: DateGroupOption[] = [
  {
    label: undefined,
    options: [
      { startDate: undefined, endDate: undefined, label: SHOW_ALL },
      { startDate: todayStart, endDate: todayEnd, label: TRADED_TODAY },
      { startDate: weekStart, endDate: weekEnd, label: TRADED_THIS_WEEK },
      { startDate: monthStart, endDate: monthEnd, label: TRADED_THIS_MONTH },
      { startDate: quarterStart, endDate: quarterEnd, label: TRADED_THIS_QUARTER },
    ],
  },
];

export const CREATED_AT_OPTIONS: DateGroupOption[] = [
  {
    label: undefined,
    options: [{ startDate: undefined, endDate: undefined, label: SHOW_ALL }],
  },
  {
    label: 'PRESENTE',
    options: [
      { startDate: todayStart, endDate: todayEnd, label: CREATED_TODAY },
      { startDate: weekStart, endDate: weekEnd, label: CREATED_THIS_WEEK },
      { startDate: monthStart, endDate: monthEnd, label: CREATED_THIS_MONTH },
      { startDate: quarterStart, endDate: quarterEnd, label: CREATED_THIS_QUARTER },
    ],
  },
  {
    label: 'ANTERIORMENTE',
    options: [
      { startDate: yesterdayStart, endDate: yesterdayEnd, label: CREATED_YESTERDAY },
      { startDate: lastWeekStart, endDate: lastWeekEnd, label: CREATED_LAST_WEEK },
      { startDate: lastMonthStart, endDate: lastMonthEnd, label: CREATED_LAST_MONTH },
      { startDate: lastQuarterStart, endDate: lastQuarterEnd, label: CREATED_LAST_QUARTER },
    ],
  },
];

export const INTERVAL_OPTIONS: DateGroupOption[] = [
  {
    label: 'Intervalo',
    options: [
      { startDate: interval30Start, endDate: todayEnd, label: LAST_30_DAYS },
      { startDate: interval60Start, endDate: todayEnd, label: LAST_60_DAYS },
      { startDate: interval90Start, endDate: todayEnd, label: LAST_90_DAYS },
      { startDate: interval180Start, endDate: todayEnd, label: LAST_180_DAYS },
      { startDate: interval365Start, endDate: todayEnd, label: LAST_365_DAYS },
    ],
  },
];
