import { Types } from '@libercapital/react-salti';

import {
  DASHBOARD_PATH,
  INVOICES_PATH,
  REPORTS_PATH,
  INVOICES_HISTORY_PATH,
  TRADES_PATH,
  VENDORS_PATH,
  LIMITS_PATH,
  DIGITAL_SIGNATURE_TEST_PATH,
  BANK_ACCOUNTS_PATH,
  ADDRESSES_PATH,
  MAIN_CONTRACT_PATH,
  REGISTRATION_DATA_PATH,
  SWAP_ORGANIZATION_PATH,
  LOGOUT_PATH,
} from 'routes/paths';

import { RouteProps } from './types';
import { Portal } from '../../types';
import { signout } from 'services/api/auth';

const patterns = {
  dashboard: [/^\/sacado\/dashboard\/{0,1}$/],
  reports: [/^\/sacado\/relatorios\/{0,1}$/],
  invoices: [
    /^\/sacado\/duplicatas\/{0,1}$/,
    /^\/sacado\/duplicatas\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/duplicatas\/[a-zA-Z0-9-]+\/edit\/{0,1}$/,
    /^\/sacado\/confirmacao_titulos\/{0,1}$/,
    // eslint-disable-next-line max-len
    /^\/sacado\/confirmacao_titulos\/(prepare_declaration|aguardando_continuacao_confirmacao|continue_declaration|new|liber_sign_direito_creditorio)\/{0,1}$/,
  ],
  allInvoices: [/^\/sacado\/duplicatas\/{0,1}$/],
  confirmationHistory: [/^\/sacado\/confirmacao_titulos\/{0,1}$/],
  trades: [/^\/sacado\/negociacoes\/{0,1}$/],
  vendors: [
    /^\/sacado\/fornecedores((\/(batch_upload|new)\/{0,1})|\/{0,1})$/,
    /^\/sacado\/fornecedores\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/fornecedores\/grupos\/{0,1}$/,
    /^\/sacado\/fornecedores\/grupos\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/fornecedores\/grupos\/[a-zA-Z0-9-]+\/historico\/{0,1}$/,
  ],
  limits: [/^\/sacado\/limites\/{0,1}$/],
  bankAccounts: [
    /^\/sacado\/conta_bancarias\/{0,1}$/,
    /^\/sacado\/conta_bancarias\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/conta_bancarias\/new\/{0,1}$/,
  ],
  addresses: [
    /^\/sacado\/enderecos\/{0,1}$/,
    /^\/sacado\/enderecos\/[a-zA-Z0-9-]+\/{0,1}$/,
    /^\/sacado\/enderecos\/new\/{0,1}$/,
  ],
  contract: [/^\/sacado\/contrato_mae\/{0,1}$/],
  userInformation: [/^\/sacados\/edit\/{0,1}$/],
  testDigitalSignature: [/^\/testar_assinatura_digital\/{0,1}$/],
};

export const routes = ({
  anticipationLimitsAvailable,
  portal,
}: {
  anticipationLimitsAvailable?: boolean;
  portal?: Portal;
}) => {
  const baseRoutes: RouteProps[] = [
    {
      label: 'Títulos',
      icon: 'Receipt',
      path: INVOICES_PATH,
      subPaths: [
        { label: 'Todos os títulos', path: INVOICES_PATH, routePatterns: patterns.allInvoices },
        {
          label: 'Histórico de confirmações',
          path: INVOICES_HISTORY_PATH,
          routePatterns: patterns.confirmationHistory,
        },
      ],
      routePatterns: patterns.invoices,
    },
    {
      label: 'Negociações',
      icon: 'Money',
      path: TRADES_PATH,
      subPaths: [],
      routePatterns: patterns.trades,
    },
    {
      label: 'Fornecedores',
      icon: 'BuildingShop',
      path: VENDORS_PATH,
      subPaths: [],
      routePatterns: patterns.vendors,
    },
  ];

  const acoCearenseSubdomains: (string | undefined)[] = [
    'siderurgicanorte',
    'acocearenseindustrial',
    'acocearensecomercial',
    'wmaparticipacoes',
    'sinobras',
  ];

  if (!acoCearenseSubdomains.includes(portal?.subdomain)) {
    baseRoutes.unshift({
      label: 'Dashboard',
      icon: 'Board',
      path: DASHBOARD_PATH,
      subPaths: [],
      routePatterns: patterns.dashboard,
    });
  }

  if (anticipationLimitsAvailable) {
    baseRoutes.push({
      label: 'Configurar limites',
      icon: 'Options',
      path: LIMITS_PATH,
      subPaths: [],
      routePatterns: patterns.limits,
    });
  }

  baseRoutes.push({
    label: 'Relatórios exportados',
    icon: 'Table',
    path: REPORTS_PATH,
    subPaths: [],
    routePatterns: patterns.reports,
  });

  return baseRoutes;
};

export const userSettings = ({
  navigate,
  usesDigitalSignature,
  loggedAsOperator,
  pathname,
}: {
  navigate: (path: string, label: string) => void;
  usesDigitalSignature?: boolean;
  loggedAsOperator?: boolean;
  pathname: string;
}) => {
  const userSettingsMenu: {
    label: string;
    path: string;
    routePatterns?: RegExp[];
    iconName?: Types.IconName;
  }[] = [
    { label: 'Contas bancárias', path: BANK_ACCOUNTS_PATH, routePatterns: patterns.bankAccounts },
    { label: 'Endereços', path: ADDRESSES_PATH, routePatterns: patterns.addresses },
    { label: 'Seu contrato', path: MAIN_CONTRACT_PATH, routePatterns: patterns.contract },
    {
      label: 'Dados cadastrados',
      path: REGISTRATION_DATA_PATH,
      routePatterns: patterns.userInformation,
    },
  ];

  if (usesDigitalSignature) {
    userSettingsMenu.push({
      label: 'Testar assinatura digital',
      path: DIGITAL_SIGNATURE_TEST_PATH,
      routePatterns: patterns.testDigitalSignature,
    });
  }

  if (loggedAsOperator) {
    userSettingsMenu.push({ label: 'Trocar de organização', path: SWAP_ORGANIZATION_PATH });
  }

  userSettingsMenu.push({ label: 'Sair', path: LOGOUT_PATH, iconName: 'SignOut' });

  return {
    avatarIcon: 'Person',
    menu: userSettingsMenu.map(({ label, path, iconName = undefined, routePatterns = [] }) => {
      if (path === LOGOUT_PATH) {
        return {
          label,
          iconName,
          divider: true,
          onClick: () => signout(),
        };
      }

      return {
        selected: routePatterns.some(pattern => pattern.test(pathname)),
        onClick: () => navigate(path, label),
        label,
        iconName,
      };
    }),
  };
};
