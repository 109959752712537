import { Router } from "react-router-dom";
import { Theme, ToastProvider } from "@libercapital/react-salti";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/pt-br";
import { ptBR } from "@mui/x-date-pickers/locales";

import Routes from "./routes";
import queryClient from "./services/queryClient";
import { AuthProvider } from "./contexts/AuthContext";
import { TourProvider } from "./contexts/TourContext";
import history from "./browserHistory";
import { setupAmplitude } from "./services/amplitude";
import Tour from "./components/Tour";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

function App() {
  const saltiTheme = createTheme(Theme);

  setupAmplitude();

  if (window.env.datadogEnabled) {
    datadogRum.init({
      applicationId: window.env.datadogApplicationId ?? "",
      clientToken: window.env.datadogClientToken ?? "",
      site: "datadoghq.com",
      service: window.env.datadogService ?? "",
      env: window.env.datadogEnvironment ?? "",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      useSecureSessionCookie: true,
    });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=1a679e6b-8acc-4fab-adde-8958674b7de3';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={
        ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <QueryClientProvider client={queryClient}>
        <StyledThemeProvider theme={saltiTheme}>
          <ThemeProvider theme={saltiTheme}>
            <CssBaseline />
            <AuthProvider>
              <ToastProvider>
                <SnackbarProvider maxSnack={3}>
                  <TourProvider>
                    <Tour />
                    <Router history={history}>
                      <Routes />
                    </Router>
                  </TourProvider>
                </SnackbarProvider>
              </ToastProvider>
            </AuthProvider>
          </ThemeProvider>
        </StyledThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
