import { Types } from '@libercapital/react-salti';

import { TradesColumnSortingKey } from 'types';

export const DUPLICATA_EXPIRES_AT = 'duplicata_expires_at';
export const DUPLICATA_EXPECTED_PAYMENT_AT = 'duplicata_expected_payment_at';
export const FINISHED_AT = 'finished_at';
export const DUPLICATA_NUMBER = 'duplicata_number_contains_any';
export const VENDOR_ID_IN = 'fornecedor_id_in';
export const FUNDER_ID_IN = 'investidor_id_in';
export const STATUS = 'status_in';
export const NEGOTIATION_DATE_AT = 'created_at_date';

export const DUPLICATA_EXPIRES_AT_LTEQ_FILTER = `q[${DUPLICATA_EXPIRES_AT}_lteq]`;
export const DUPLICATA_EXPIRES_AT_GTEQ_FILTER = `q[${DUPLICATA_EXPIRES_AT}_gteq]`;
export const FINISHED_AT_LTEQ_FILTER = `q[${FINISHED_AT}_lteq]`;
export const FINISHED_AT_GTEQ_FILTER = `q[${FINISHED_AT}_gteq]`;
export const DUPLICATA_EXPECTED_PAYMENT_AT_LTEQ_FILTER = `q[${DUPLICATA_EXPECTED_PAYMENT_AT}_lteq]`;
export const DUPLICATA_EXPECTED_PAYMENT_AT_GTEQ_FILTER = `q[${DUPLICATA_EXPECTED_PAYMENT_AT}_gteq]`;
export const DUPLICATA_NUMBER_FILTER = `q[${DUPLICATA_NUMBER}]`;
export const VENDOR_ID_IN_FILTER = `q[${VENDOR_ID_IN}]`;
export const FUNDER_ID_IN_FILTER = `q[${FUNDER_ID_IN}]`;
export const STATUS_IN_FILTER = `q[${STATUS}]`;
export const NEGOTIATION_DATE_AT_LTEQ_FILTER = `q[${NEGOTIATION_DATE_AT}_lteq]`;
export const NEGOTIATION_DATE_AT_GTEQ_FILTER = `q[${NEGOTIATION_DATE_AT}_gteq]`;

export const tradesColumns: {
  label: string;
  key?: TradesColumnSortingKey;
  align?: Types.TableCellProps['align'];
}[] = [
  { label: 'Nº do título', key: 'duplicata_number' },
  { label: 'Status' },
  { label: 'Valor bruto (R$)', key: 'duplicata_value', align: 'right' },
  { label: 'Taxa desc.', key: 'duplicata_agreed_rate', align: 'right' },
  { label: 'D. Venc.', key: 'duplicata_expires_at', align: 'right' },
  { label: 'D. Liq.', key: 'expected_payment_at', align: 'right' },
  { label: 'D. Negociação', key: 'negotiation_created_at', align: 'right' },
  { label: 'Financiador', key: 'investidor_name' },
  { label: 'Fornecedor', key: 'fornecedor_trade_name' },
];

export const DATES_KEY_MAPPING = {
  [DUPLICATA_EXPIRES_AT]: 'expiresAt',
  [DUPLICATA_EXPECTED_PAYMENT_AT]: 'expectedPaymentAt',
  [FINISHED_AT]: 'finishedAt',
  [NEGOTIATION_DATE_AT]: 'negotiationDate',
};
